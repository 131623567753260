import "../themes/fonts.css"

export default {
  font: {
    heading: "IM Fell French Canon SC', serif",
    primary: "Muli, sans-serif",
    secondary: "Roboto Mono, monospace",
  },
  fontSize: {
    xxs: ".25rem",
    xs: ".5rem",
    s: "1rem",
    m: "1.25rem",
    l: "1.5rem",
    xl: "1.75rem",
    xxl: "2rem",
  },
  color: {
    primary: "#fff",
    secondary: "#000",
    tertiary: "rgb(235, 98, 9)",
  },
  breakpoint: {
    mobile: "50rem",
    tablet: "65rem",
    desktop: "80rem",
  },
  spacing: {
    xxs: ".25rem",
    xs: ".5rem",
    s: "1rem",
    m: "2rem",
    l: "3rem",
    xl: "4rem",
    xxl: "5rem",
  },
  size: {
    xxs: "1rem",
    xs: "3rem",
    s: "5rem",
    m: "7rem",
    l: "9rem",
    xl: "11rem",
    xxl: "13rem",
    xxxl: "16rem",
  },
  container: {
    xxxs: "10%",
    xxs: "20%",
    xs: "30%",
    s: "40%",
    m: "50%",
    l: "60%",
    xl: "70%",
    xxl: "80%",
    xxxl: "90%",
  },
}
